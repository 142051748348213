import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

function ReactSelect({...props}) {
    const animatedComponents = makeAnimated();
    return (
        <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            styles={
                {
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    option: provided => ({
                        ...provided,
                        color: '#000'
                    }),
                    control: provided => ({
                        ...provided,
                        color: '#fff',
                        boxShadow: 'none',
                    }),
                    singleValue: provided => ({
                        ...provided,
                        color: '#808a9a'
                    })
                }
            }
            {...props}
        />
    )
}

export default ReactSelect;
