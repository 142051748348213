const initialState = {
    dataJenisBantuans: [],
    modalJenisBantuan: false,
    formJenisBantuan: {
        Id: '',
        Keterangan: ''
    }
}

const jenisBantuanReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_JENIS_BANTUANS':
            return { ...state, dataJenisBantuans: action.payload }
        case 'FORM_JENIS_BANTUAN':
            return {
                ...state,
                formJenisBantuan: {
                    ...state.formJenisBantuan,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_JENIS_BANTUAN':
            return { ...state, formJenisBantuan: initialState.formJenisBantuan }
        case 'MODAL_JENIS_BANTUAN':
            return { ...state, modalJenisBantuan: action.payload }
        default:
            return state
    }
}

export default jenisBantuanReducer;