const initialState = {
    dataDesas: [],
    modalDesa: false,
    formDesa: {
        Kode: '',
        Nama: '',
        KodePos: '',
    },
}

const wilDesaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_DESAS':
            return { ...state, dataDesas: action.payload }
        case 'FORM_DESA':
            return {
                ...state,
                formDesa: {
                    ...state.formDesa,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_DESA':
            return { ...state, formDesa: initialState.formDesa }
        case 'MODAL_DESA':
            return { ...state, modalDesa: action.payload }
        default:
            return state
    }
}

export default wilDesaReducer;