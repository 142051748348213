const initialState = {
  dataUploadBayars: [],
  modalUploadBayar: false,
  formUploadBayar: {
    IdPermohonan: "",
    TanggalBayar: "",
    RpBayar: "",
    NoPermohonan: "",
    Nama: "",
    Email: "",
  },
  ///VERIFIKASI
  dataVerifikasiBayars: [],
  modalVerifikasiBayar: false,
  formVerifikasiBayar: {
    IdPermohonan: "",
    TanggalBayar: "",
    RpBayar: "",
    StatusProses: "",
  },
};

const pembayaranReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_UPLOAD_BAYARS":
      return { ...state, dataUploadBayars: action.payload };
    case "FORM_UPLOAD_BAYAR":
      return {
        ...state,
        formUploadBayar: {
          ...state.formUploadBayar,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_UPLOAD_BAYAR":
      return {
        ...state,
        formUploadBayar: initialState.formUploadBayar,
      };
    case "MODAL_UPLOAD_BAYAR":
      return { ...state, modalUploadBayar: action.payload };
    ////VERIFIKASI
    case "DATA_VERIFIKASI_BAYARS":
      return { ...state, dataVerifikasiBayars: action.payload };
    case "FORM_VERIFIKASI_BAYAR":
      return {
        ...state,
        formVerifikasiBayar: {
          ...state.formVerifikasiBayar,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_VERIFIKASI_BAYAR":
      return {
        ...state,
        formVerifikasiBayar: initialState.formVerifikasiBayar,
      };
    case "MODAL_VERIFIKASI_BAYAR":
      return { ...state, modalVerifikasiBayar: action.payload };
    case "MODAL_VERIFIKASI_BAYAR_DOWNLOAD":
      return { ...state, modalVerifikasiBayarDownload: action.payload };
    default:
      return state;
  }
};

export default pembayaranReducer;
