const initialState = {
  dataPerpanjangLisensiSurveyors: [],
  formPerpanjangLisensiSurveyor: {
    TanggalLisensiBaru: null,
    FileDownloads: [],
    Catatan: "",
  },
  modalPerpanjangLisensiSbDetail: false,
};

const perpanjangLisensiSbReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_PERPANJANG_LISENSI_SURVEYORS":
      return { ...state, dataPerpanjangLisensiSurveyors: action.payload };
    case "FORM_PERPANJANG_LISENSI_SURVEYOR":
      return {
        ...state,
        formPerpanjangLisensiSurveyor: {
          ...state.formPerpanjangLisensiSurveyor,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_PERPANJANG_LISENSI_SURVEYOR":
      return {
        ...state,
        formPerpanjangLisensiSurveyor:
          initialState.formPerpanjangLisensiSurveyor,
      };
    case "MODAL_PERPANJANG_LISENSI_SURVEYOR_DETAIL":
      return { ...state, modalPerpanjangLisensiSbDetail: action.payload };
    default:
      return state;
  }
};

export default perpanjangLisensiSbReducer;
