import { http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setDataKjskbs = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKjsbs`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_KJSKBS", payload: data.Data });
      } else {
        // Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
      }
    });
  };
};

export const setDataKjskb = (idKjsb) => {
  loadingBar(true);
  return (dispatch) => {
    dispatch({ type: "RESET_FORM_KJSKB" });
    http.get(`${URLSVC}/Webs/GetKjsb?IdKjsb=${idKjsb}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch(setFormKjskb("IdKjsb", idKjsb));
        dispatch(setFormKjskb("KdKjsb", data.Data.KdKjsb));
        dispatch(setFormKjskb("Nama", data.Data.Nama));
        dispatch(setFormKjskb("Npwp", data.Data.Npwp));
        dispatch(setFormKjskb("NoKontak", data.Data.NoKontak));
        dispatch(setFormKjskb("Email", data.Data.Email));
        dispatch(setFormKjskb("Alamat", data.Data.Alamat));
        dispatch(setFormKjskb("NoIzinMenteri", data.Data.NoIzinMenteri));
        dispatch(setFormKjskb("KartuAnggota", data.Data.KartuAnggota));
        dispatch(setFormKjskb("FileDownloads", data.Data.Files));
        dispatch(setFormKjskb("IsPlm", data.Data.IsPlm));

        const SELECT = {
          IdPimpinan: {
            value: data.Data.Pimpinan.Id,
            label: data.Data.Pimpinan.Nama,
          },
          IdWilker: {
            value: data.Data.Wilker.Id,
            label: data.Data.Wilker.Nama,
          },
        };
        dispatch(setFormKjskb("IdPimpinan", SELECT.IdPimpinan));
        dispatch(setFormKjskb("IdWilker", SELECT.IdWilker));

        dispatch(setDataAktifSurveyors());
        var pendiris = [];
        data.Data.Pendiris.map((v, i) =>
          pendiris.push({
            value: v.Id,
            label: v.Nama,
          })
        );
        dispatch(setFormKjskb("Pendiris", pendiris));
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataKjsbByUser = () => {
  loadingBar(true);
  return (dispatch) => {
    dispatch({ type: "RESET_FORM_KJSKB" });
    http.get(`${URLSVC}/Webs/GetKjsbByUser`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch(setFormKjskb("KdKjsb", data.Data.KdKjsb));
        dispatch(setFormKjskb("Nama", data.Data.Nama));
        dispatch(setFormKjskb("Latitude", data.Data.Lat));
        dispatch(setFormKjskb("Longitude", data.Data.Lon));
        dispatch(setFormKjskb("Npwp", data.Data.Npwp));
        dispatch(setFormKjskb("NoKontak", data.Data.NoKontak));
        dispatch(setFormKjskb("Email", data.Data.Email));
        dispatch(setFormKjskb("Alamat", data.Data.Alamat));
        dispatch(setFormKjskb("NoIzinMenteri", data.Data.NoIzinMenteri));
        dispatch(setFormKjskb("KartuAnggota", data.Data.KartuAnggota));
        dispatch(setFormKjskb("KopSurat", data.Data.KopSurat));
        dispatch(setFormKjskb("FileDownloads", data.Data.Files));

        const SELECT = {
          IdPimpinan: {
            value: data.Data.Pimpinan.Id,
            label: data.Data.Pimpinan.Nama,
          },
          IdWilker: {
            value: data.Data.Wilker.Id,
            label: data.Data.Wilker.Nama,
          },
        };
        dispatch(setFormKjskb("IdPimpinan", SELECT.IdPimpinan));
        dispatch(setFormKjskb("IdWilker", SELECT.IdWilker));

        dispatch(setDataAktifSurveyors());
        var pendiris = [];
        data.Data.Pendiris.map((v, i) =>
          pendiris.push({
            value: v.Id,
            label: v.Nama,
          })
        );
        dispatch(setFormKjskb("Pendiris", pendiris));
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataAktifSurveyors = () => {
  return (dispatch) => {
    // http.get(`${URLSVC}/Webs/GetSurveyorNoKjsb`).then((res) => {
    http.get(`${URLSVC}/Webs/GetAllSurveyors`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        var array = [];
        data.Data.map((v, i) =>
          array.push({
            value: v.IdSurveyor,
            label: v.Nama,
          })
        );
        dispatch({ type: "DATA_AKTIF_SURVEYORS", payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setFileKjskb = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetJenisKjsbFiles`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type, payload: data.Data });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataRegKjsbs = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Registrasis/GetKjsbs`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_REG_KJSBS", payload: data.Data });
        let countReg = 0,
          countMenungguPem = 0,
          countUploadBayar = 0,
          countTerdaftar = 0;

        data.Data.map((v, i) => {
          if (v.Status === "Registrasi") countReg += 1;
          if (v.Status === "Verifikasi") countMenungguPem += 1;
          if (v.Status === "Upload Bukti Bayar") countUploadBayar += 1;
          if (v.Status === "Anggota MASKI") countTerdaftar += 1;
        });

        dispatch({
          type: "DATA_COUNT_REG_KJSB",
          payload: {
            Registrasi: countReg,
            MenungguPembayaran: countMenungguPem,
            UploadBuktiBayar: countUploadBayar,
            Terdaftar: countTerdaftar,
          },
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataRegKjsb = (idRegKjsb) => {
  loadingBar(true);
  return (dispatch) => {
    http
      .get(`${URLSVC}/Registrasis/GetKjsb?IdRegKjsb=${idRegKjsb}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_REG_KJSB", payload: data.Data });
          dispatch(setFormKjskb("IdRegKjsb", idRegKjsb));
          dispatch(setFormKjskb("NoRegKjsb", data.Data.NoReg));
          dispatch(setFormKjskb("Email", data.Data.Email));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataKjsbPengalamansByUser = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKjsbPengalamansByUser`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_PENGALAMAN_KJSBS", payload: data.Data });
      } else {
        // Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
      }
    });
  };
};

export const setDataKjsbPengalaman = (idKjsbPengalaman) => {
  loadingBar(true);
  return (dispatch) => {
    dispatch({ type: "DATA_PENGALAMAN_KJSB", payload: false });
    http
      .get(
        `${URLSVC}/Webs/GetKjsbPengalaman?IdKjsbPengalaman=${idKjsbPengalaman}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_PENGALAMAN_KJSB", payload: data.Data });
          dispatch(
            setFormKjskb("IdKjsbPengalaman", data.Data.IdKjsbPengalaman)
          );
          dispatch(setFormKjskb("Nama", data.Data.Nama));
          dispatch(setFormKjskb("Keterangan", data.Data.Keterangan));
          dispatch(setFormKjskb("PreviewFileImage", data.Data.NamaFile));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setFormKjskb = (formType, formValue) => {
  return { type: "FORM_KJSKB", formType, formValue };
};

export const apiAddKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("Nama", iData.Nama);
    fd.append("Npwp", iData.Npwp);
    fd.append("NoKontak", iData.NoKontak);
    fd.append("Email", iData.Email);
    fd.append("Alamat", iData.Alamat);
    fd.append("NoIzinMenteri", iData.NoIzinMenteri);
    fd.append("IdPimpinan", iData.IdPimpinan.value);
    fd.append("IdWilker", iData.IdWilker.value);

    iData.Pendiris.map((v, i) => fd.append(`Pendiris[${i}]`, v.value));

    if (iData.Files !== "") {
      iData.Files.map((v, i) => {
        if (v.Kode !== null) {
          fd.append(`Files[${i}].IdFile`, v.IdFile);
          fd.append(`Files[${i}].FileUpload`, v.FileUpload);
        }
      });
    }

    http
      .post(`${URLSVC}/Webs/AddKjsb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil menambah data`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_KJSKB", payload: false });
            dispatch(setDataKjskbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiEditKjsb = (iData, files) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdKjsb", iData.IdKjsb);
    fd.append("KdKjsb", iData.KdKjsb);
    fd.append("Nama", iData.Nama);
    fd.append("Npwp", iData.Npwp);
    fd.append("NoKontak", iData.NoKontak);
    fd.append("Email", iData.Email);
    fd.append("Alamat", iData.Alamat);
    fd.append("NoIzinMenteri", iData.NoIzinMenteri);
    fd.append("IdPimpinan", iData.IdPimpinan.value);
    fd.append("IdWilker", iData.IdWilker.value);
    fd.append("IsPlm", iData.IsPlm);

    iData.Pendiris.map((v, i) => fd.append(`Pendiris[${i}]`, v.value));

    var xi = 0;
    if (files !== "") {
      files.map((v, i) => {
        if (v.IdFile !== undefined) {
          console.log("files : ", v.IdFile);
          fd.append(`Files[${xi}].IdFile`, v.IdFile);
          fd.append(`Files[${xi}].FileUpload`, v.FileUpload);
          xi++;
        }
      });
    }

    // return;
    http
      .post(`${URLSVC}/Webs/EditKjsb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil memperbarui data`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_KJSKB", payload: false });
            dispatch(setDataKjskbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiEditKjsbByUser = (iData, files, fileKopSurat) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("KdKjsb", iData.KdKjsb);
    fd.append("Nama", iData.Nama);
    fd.append("Lat", iData.Latitude);
    fd.append("Lon", iData.Longitude);
    fd.append("Npwp", iData.Npwp);
    fd.append("NoKontak", iData.NoKontak);
    fd.append("Email", iData.Email);
    fd.append("Alamat", iData.Alamat);
    fd.append("NoIzinMenteri", iData.NoIzinMenteri);
    fd.append("IdPimpinan", iData.IdPimpinan.value);
    fd.append("IdWilker", iData.IdWilker.value);
    fd.append("FileKopSurat", fileKopSurat);

    iData.Pendiris.map((v, i) => fd.append(`Pendiris[${i}]`, v.value));

    var xi = 0;
    if (files !== "") {
      files.map((v, i) => {
        if (v.Kode !== null) {
          console.log("files : ", v.Kode);
          fd.append(`Files[${xi}].IdFile`, v.Kode);
          fd.append(`Files[${xi}].FileUpload`, v.FileUpload);
          xi++;
        }
      });
    }

    http
      .post(`${URLSVC}/Webs/EditKjsbByUser`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil memperbarui data profil`,
            "success"
          ).then(function () {
            window.location.reload();
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

////// ANGGOTA //////

export const setDataAnggotaKjskbs = (idKjsb) => {
  return (dispatch) => {
    dispatch({ type: "DATA_ANGGOTA_KJSKBS", payload: [] });
    http
      .get(`${URLSVC}/Webs/GetSurveyorsByKjsb?IdKjsb=${idKjsb}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_ANGGOTA_KJSKBS", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataMasterKjsbDataSbs = (idKjsb) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKjsbDataSb`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_MASTER_KJSB_DATA_SB", payload: data.Data });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiAddKjsbAnggota = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdKjsb", iData.IdKjsb);

    iData.Anggotas.map((v, i) => fd.append(`IdSurveyors[${i}]`, v.value));

    http
      .post(`${URLSVC}/Webs/AddKjsbAnggota`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire("Berhasil", `Anda telah berhasil menambah data`, "success");
          dispatch({ type: "MODAL_ANGGOTA_KJSKB", payload: false });
          dispatch(setDataAnggotaKjskbs(iData.IdKjsb));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiDeleteKjsbAnggota = (idKjsb, idSurveyors) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("IdKjsb", idKjsb);
    idSurveyors.map((v, i) => fd.append(`IdSurveyors[${i}]`, v));
    http
      .post(`${URLSVC}/Webs/DeleteKjsbAnggota`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil menghapus data`,
            "success"
          );
          dispatch(setDataAnggotaKjskbs(idKjsb));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiSetStatusKjsb = (idKjskb, kjskb, status) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan perbarui status kjskb (${kjskb})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .post(
            `${URLSVC}/Webs/SetStatusKjsb?IdKjsb=${idKjskb}&Status=${status}`
          )
          .then((res) => {
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil memperbarui status kjsb (${kjskb})`,
                "success"
              );
              dispatch(setDataKjskbs());
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiApproveDataKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("IdRegKjsb", iData.IdRegKjsb);
    fd.append("Catatan", iData.CatatanData);

    http
      .post(`${URLSVC}/Webs/ApproveDataKjsb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil approve data`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_REG_KJSB_DATA", payload: false });
            dispatch(setDataRegKjsbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
export const apiRejectDataKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdRegKjsb", iData.IdRegKjsb);
    fd.append("Catatan", iData.CatatanData);

    http
      .post(`${URLSVC}/Webs/RejectDataKjsb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil reject data`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_REG_KJSB_DATA", payload: false });
            dispatch(setDataRegKjsbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
export const apiApprovePayKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdRegKjsb", iData.IdRegKjsb);
    fd.append("Catatan", iData.CatatanPay);
    fd.append("JumlahTahun", iData.JumlahTahun.value);

    http
      .post(`${URLSVC}/Webs/ApprovePayKjsb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil approve pembayaran`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_REG_KJSB_PAY", payload: false });
            dispatch(setDataRegKjsbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
export const apiRejectPayKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdRegKjsb", iData.IdRegKjsb);
    fd.append("Catatan", iData.CatatanPay);

    http
      .post(`${URLSVC}/Webs/RejectPayKjsb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil reject pembayaran`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_REG_KJSB_PAY", payload: false });
            dispatch(setDataRegKjsbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiSetAktifKjsbAnggotaSb = (idSurveyor, nama) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan aktivasi SB (${nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .get(`${URLSVC}/Webs/SetAktifKjsbAnggotaSb?IdSurveyor=${idSurveyor}`)
          .then((res) => {
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil aktivasi SB (${nama})`,
                "success"
              );
              dispatch(setDataMasterKjsbDataSbs());
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
export const apiDeleteKjsbAnggotaSb = (idSurveyor, nama) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus SB (${nama}) dari KJSB!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .get(`${URLSVC}/Webs/DeleteKjsbAnggotaSb?IdSurveyor=${idSurveyor}`)
          .then((res) => {
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil hapus SB (${nama}) dari KJSB`,
                "success"
              );
              dispatch(setDataMasterKjsbDataSbs());
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiAddKjsbPengamalan = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("Nama", iData.Nama);
    fd.append("Keterangan", iData.Keterangan);
    fd.append("FileUpload", iData.FileUpload);

    http
      .post(`${URLSVC}/Webs/AddKjsbPengamalan`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda berhasil tambah pengalaman`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_PENGALAMAN_KJSB", payload: false });
            dispatch(setDataKjsbPengalamansByUser());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiEditKjsbPengamalan = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdKjsbPengalaman", iData.IdKjsbPengalaman);
    fd.append("Nama", iData.Nama);
    fd.append("Keterangan", iData.Keterangan);
    fd.append("FileUpload", iData.FileUpload);

    http
      .post(`${URLSVC}/Webs/EditKjsbPengamalan`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda berhasil perbarui pengalaman`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_PENGALAMAN_KJSB", payload: false });
            dispatch(setDataKjsbPengalamansByUser());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiDeleteKjsbPengalaman = (idKjsbPengalaman, nama) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus pengalaman dari KJSB!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .get(
            `${URLSVC}/Webs/DeleteKjsbPengalaman?IdKjsbPengalaman=${idKjsbPengalaman}`
          )
          .then((res) => {
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil hapus pengalaman dari KJSB`,
                "success"
              );
              dispatch(setDataKjsbPengalamansByUser());
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiUpdateDataRegKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);

    http
      .get(
        `${URLSVC}/Webs/UpdateDataRegKjsb?IdRegKjsb=${iData.IdRegKjsb}&Email=${iData.Email}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire("Berhasil", `Berhasil perbarui data`, "success").then(
            function () {
              dispatch({ type: "MODAL_REG_KJSB_DATA", payload: false });
              dispatch(setDataRegKjsbs());
            }
          );
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiResendEmailPayKjsb = (idRegKjsb) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan kirim ulang email pembayaran!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(`${URLSVC}/Webs/ResendEmailPayKjsb?IdRegKjsb=${idRegKjsb}`)
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil kirim ulang email pembayaran`,
                "success"
              ).then(function () {
                dispatch(setDataRegKjsbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
