import { http } from "src/config";
import {
  FUNCArraySelectId,
  FUNCDateDmytoYmd,
  FUNCDateToString,
  FUNCNumberFormat,
} from "src/config/function";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar, setDataPermohonans } from "../globalAction";

export const setDataBiayaJadwalPermohonan = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_BIAYA_JADWAL_PERMOHONAN" });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "MODAL_BIAYA_JADWAL_PERMOHONAN",
            payload: true,
          });
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "IdPermohonan",
              data.Data.IdPermohonan
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "NoPermohonan",
              data.Data.NoPermohonan
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "IdJenisPermohonan",
              data.Data.JenisPermohonan.Id
            )
          );
          dispatch(setFormBiayaJadwalDataPermohonan("Nama", data.Data.Nama));
          dispatch(setFormBiayaJadwalDataPermohonan("Email", data.Data.Email));
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "JenisPermohonan",
              data.Data.JenisPermohonan.Nama
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "AlamatBidangTanah",
              data.Data.AlamatBidangTanah
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "JarakLokasiBidangTanah",
              data.Data.JarakLokasiBidangTanah
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("LuasTanah", data.Data.LuasTanah)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("Latitude", data.Data.Latitude)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("Longitude", data.Data.Longitude)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("Wilker", data.Data.Wilker)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("Kjsb", data.Data.Kjsb.Nama)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "JadwalUkur",
              data.Data.JadwalUkur &&
                new Date(FUNCDateDmytoYmd(data.Data.JadwalUkur))
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "JamUkurAwal",
              data.Data.JadwalJamUkur1
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "JamUkurAkhir",
              data.Data.JadwalJamUkur2
            )
          );

          dispatch(
            setDataBiayaJadwalSurveyors(
              "DATA_BIAYA_JADWAL_SURVEYORS",
              data.Data.Kjsb.Id
            )
          );
        } else {
          window.location.replace("/#/hitung-biaya-penjadwalan");
        }
      });
  };
};

export const setDataBiayaJadwalSurveyors = (type, idKjskb) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Webs/GetSurveyorsByKjsb?IdKjsb=${idKjskb}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          var array = [];
          data.Data.map((v, i) => {
            array.push({
              value: v.IdSurveyor,
              label: `${v.Nama} / ${v.NoLisensi}`,
              image: v.PasFoto,
            });
          });
          dispatch({ type, payload: array });
        } else {
          // Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
        }
      });
  };
};

export const setDataHitungBiaya = (
  IdJenisPermohonan,
  IdJenisProperty,
  JumlahTim,
  JumlahHari,
  IdWilker,
  LuasTanah,
  Jarak,
  RpLainnya,
  Diskon = 0,
  setValue
) => {
  return (dispatch) => {
    loadingBar(true);
    http
      .get(
        `${URLSVC}/Pelayanans/HitungBiaya?IdJenisPermohonan=${IdJenisPermohonan}&IdJenisProperty=${IdJenisProperty}&IdWilker=${IdWilker}&LuasTanahM2=${LuasTanah}&Jarak=${Jarak}&JumlahTim=${JumlahTim}&JumlahHari=${JumlahHari}&Diskon=${FUNCNumberFormat(
          Diskon
        )}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          // setValue("LuasTanah", LuasTanah);
          // setValue("JarakLokasiBidangTanah", Jarak);
          setValue("RpPnbp", data.Data.RpPnbp);
          setValue("RpTranspotasi", data.Data.RpTranspotasi);
          setValue(
            "RpTotalAkomodasi",
            data.Data.RpAkomodasi + data.Data.RpTranspotasi
          );
          setValue("RpLainnya", data.Data.RpLainnya);
          setValue("RpTotal", data.Data.RpTotal);
          setValue("Biayas", data.Data.Details);

          data.Data.Details.map((v, i) => {
            if (v.Item === "Biaya Pengukuran") {
              setValue("RpUkur", v.Rp);
            }
            if (v.Item === "Biaya Layanan") {
              setValue("RpLayanan", v.Rp);
            }
            if (v.Item === "PPN (11%)") {
              setValue("RpPpn", v.Rp);
            }
          });

          dispatch(
            setFormBiayaJadwalDataPermohonan("RpUkur", data.Data.RpUkur)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("RpPnbp", data.Data.RpPnbp)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "RpTranspotasi",
              data.Data.RpTranspotasi
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("RpLayanan", data.Data.RpLayanan)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("RpPpn", data.Data.RpPpn11)
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan(
              "RpTotalAkomodasi",
              data.Data.RpAkomodasi + data.Data.RpTranspotasi
            )
          );
          dispatch(
            setFormBiayaJadwalDataPermohonan("RpTotal", data.Data.RpTotal)
          );

          dispatch(
            setFormBiayaJadwalDataPermohonan("RpDetails", data.Data.Details)
          );
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setFormBiayaJadwalDataPermohonan = (formType, formValue) => {
  return { type: "FORM_BIAYA_JADWAL_PERMOHONAN", formType, formValue };
};

export const apiSetJadwalBiaya = (iData, history) => {
  return (dispatch) => {
    var fd = new FormData();
    if (iData.IdSurveyor === "") {
      Swal.fire("Gagal", `Surveyor wajib dipilih`, "info");
      return;
    }
    if (iData.NoSuratTugas === "") {
      Swal.fire("Gagal", `Nomor Surat Tugas wajib diisi`, "info");
      return;
    }
    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("JadwalUkur", FUNCDateToString(iData.JadwalUkur));
    fd.append("IdSurveyor", iData.IdSurveyor.value);
    // fd.append("JamUkurAwal", `${iData.JamUkurAwal}:00`);
    // fd.append("JamUkurAkhir", `${iData.JamUkurAkhir}:00`);

    ///sementara
    fd.append("JamUkurAwal", `00:00:00`);
    fd.append("JamUkurAkhir", `00:00:00`);

    fd.append("RpAkomodasi", iData.RpTotalAkomodasi || 0);
    fd.append("RpLainnya", iData.RpLainnya || 0);
    fd.append("RpTotal", iData.RpTotal);
    fd.append("RpPnbp", iData.RpPnbp || 0);
    fd.append("RpUkur", iData.RpUkur);
    fd.append("RpLayanan", iData.RpLayanan);
    fd.append("NoSuratTugas", iData.NoSuratTugas);
    fd.append("JarakLokasiBidangTanah", iData.JarakLokasiBidangTanah);
    fd.append("LuasTanah", iData.LuasTanah);

    iData.Biayas.map((v, i) => {
      fd.append(`Biayas[${i}].IdBiayaLayananJenis`, v.IdBiayaLayananJenis);
      fd.append(`Biayas[${i}].Rp`, v.Rp);
    });

    loadingBar(true);
    http.post(`${URLSVC}/Pelayanans/SetJadwalBiaya`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil atur biaya & jadwal`,
          "success"
        ).then(function () {
          history.goBack();
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
export const apiPersetujuanJadwal = (iData, history) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("JadwalUkur", FUNCDateToString(iData.JadwalUkur));
    // fd.append("JamUkurAwal", iData.JamUkurAwal);
    // fd.append("JamUkurAkhir", iData.JamUkurAkhir);
    ///sementara
    fd.append("JamUkurAwal", "00:00:00");
    fd.append("JamUkurAkhir", "00:00:00");
    fd.append("StatusProses", iData.StatusProses.value);
    fd.append("Catatan", iData.Catatan);

    loadingBar(true);
    http.post(`${URLSVC}/Pelayanans/PersetujuanJadwal`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil setujui penjadwalan`,
          "success"
        ).then(function () {
          dispatch({
            type: "MODAL_BIAYA_JADWAL_PERMOHONAN",
            payload: false,
          });
          dispatch(setDataPermohonans("DATA_BIAYA_JADWAL_PERMOHONAN"));
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
