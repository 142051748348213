import {
  DaftarSb,
  DaftarKjsb,
  UploadBayarSb,
  UploadBayarKjsb,
  DaftarPemohon,
  PerpanjangSb,
  PerpanjangKjsb,
} from "src/views/guest";

const guest = [
  {
    path: "/form-pendaftaran-sb",
    exact: true,
    name: "FORM DAFTAR SB",
    Component: DaftarSb,
  },
  {
    path: "/form-pendaftaran-kjsb",
    exact: true,
    name: "FORM DAFTAR KJSB",
    Component: DaftarKjsb,
  },
  {
    path: "/daftar-pemohon",
    exact: true,
    name: "FORM DAFTAR PEMOHON",
    Component: DaftarPemohon,
  },
  {
    path: "/upload-bayar-sb/:id",
    exact: false,
    name: "UPLOAD BUKTI BAYAR",
    Component: UploadBayarSb,
  },
  {
    path: "/upload-bayar-kjsb/:id",
    exact: false,
    name: "UPLOAD BUKTI BAYAR",
    Component: UploadBayarKjsb,
  },
  {
    path: "/perpanjang-sb",
    exact: false,
    name: "PERPANJANG SURVEYOR",
    Component: PerpanjangSb,
  },
  {
    path: "/perpanjang-kjsb",
    exact: false,
    name: "PERPANJANG KJSB",
    Component: PerpanjangKjsb,
  },
];

export default guest;
