const initialState = {
  dataUploadBayarCekPlots: [],
  modalUploadBayarCekPlot: false,
  formUploadBayarCekPlot: {
    IdPermohonan: "",
    TanggalBayar: "",
    RpBayar: "",
    NoPermohonan: "",
    Nama: "",
    Email: "",
  },
  ///VERIFIKASI
  dataVerifikasiBayarCekPlots: [],
  modalVerifikasiBayarCekPlot: false,
  formVerifikasiBayarCekPlot: {
    IdPermohonan: "",
    TanggalBayar: "",
    RpBayar: "",
    BuktiBayar: "",
  },
  modalVerifikasiBayarDownloadCekPlot: false,
  ///INPUTHASIL
  dataInputHasilCekPlots: [],
  modalInputHasilCekPlot: false,
  formInputHasilCekPlot: {
    NoPermohonan: "",
    Nama: "",
    Catatan: "",
    StatusProses: "",
  },
};

const cekPlotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_UPLOAD_BAYAR_CEK_PLOTS":
      return { ...state, dataUploadBayarCekPlots: action.payload };
    case "FORM_UPLOAD_BAYAR_CEK_PLOT":
      return {
        ...state,
        formUploadBayarCekPlot: {
          ...state.formUploadBayarCekPlot,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_UPLOAD_BAYAR_CEK_PLOT":
      return {
        ...state,
        formUploadBayarCekPlot: initialState.formUploadBayarCekPlot,
      };
    case "MODAL_UPLOAD_BAYAR_CEK_PLOT":
      return { ...state, modalUploadBayarCekPlot: action.payload };
    ////VERIFIKASI
    case "DATA_VERIFIKASI_BAYAR_CEK_PLOTS":
      return { ...state, dataVerifikasiBayarCekPlots: action.payload };
    case "FORM_VERIFIKASI_BAYAR_CEK_PLOT":
      return {
        ...state,
        formVerifikasiBayarCekPlot: {
          ...state.formVerifikasiBayarCekPlot,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_VERIFIKASI_BAYAR_CEK_PLOT":
      return {
        ...state,
        formVerifikasiBayarCekPlot: initialState.formVerifikasiBayarCekPlot,
      };
    case "MODAL_VERIFIKASI_BAYAR_CEK_PLOT":
      return { ...state, modalVerifikasiBayarCekPlot: action.payload };
    case "MODAL_VERIFIKASI_BAYAR_DOWNLOAD_CEK_PLOT":
      return { ...state, modalVerifikasiBayarDownloadCekPlot: action.payload };
    ////INPUTHASIL
    case "DATA_INPUT_HASIL_CEK_PLOTS":
      return { ...state, dataInputHasilCekPlots: action.payload };
    case "FORM_INPUT_HASIL_CEK_PLOT":
      return {
        ...state,
        formInputHasilCekPlot: {
          ...state.formInputHasilCekPlot,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_INPUT_HASIL_CEK_PLOT":
      return {
        ...state,
        formInputHasilCekPlot: initialState.formInputHasilCekPlot,
      };
    case "MODAL_INPUT_HASIL_CEK_PLOT":
      return { ...state, modalInputHasilCekPlot: action.payload };
    case "MODAL_INPUT_HASIL_DOWNLOAD_CEK_PLOT":
      return { ...state, modalInputHasilDownloadCekPlot: action.payload };
    default:
      return state;
  }
};

export default cekPlotReducer;
