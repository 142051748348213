const initialState = {
  dataPermohonans: [],
  modalPermohonan: false,
  formPermohonan: {
    IdPermohonan: "",
    IsDiwakilkan: "",
    IdJenisPermohonan: "",
    IdJenisSertipikat: "",
    IdKjsb: "",
    IdProv: "",
    IdKabKot: "",
    IdKec: "",
    IdDesa: "",
    NoPermohonan: "",
    NoSertipikat: "",
    Nama: "",
    NamaKjskb: "",
    NoKtp: "",
    Alamat: "",
    NoHp: "",
    Email: "",
    AlamatBidangTanah: "",
    LuasTanah: "",
    JarakLokasiBidangTanah: "",
    Files: "",
    JumlahBidangSebelum: "",
    JumlahBidangSetelah: "",
    Latitude: "",
    Longitude: "",

    IsInputJumlahBidang: false,
    Catatan: "",
    RpChekplot: "",
  },
  fileSyaratPermohonan: [],
  berkasPermohonan: [],
  modalDownloadPermohonan: false,
  statusBerkasPermohonan: [],
  modalPermohonanValidasi: false,
};

const permohonanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_PERMOHONANS":
      return { ...state, dataPermohonans: action.payload };
    case "FORM_PERMOHONAN":
      return {
        ...state,
        formPermohonan: {
          ...state.formPermohonan,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_PERMOHONAN":
      return { ...state, formPermohonan: initialState.formPermohonan };
    case "MODAL_PERMOHONAN":
      return { ...state, modalPermohonan: action.payload };
    case "MODAL_DOWNLOAD_PERMOHONAN":
      return { ...state, modalDownloadPermohonan: action.payload };
    case "FILE_SYARAT_PERMOHONAN":
      return { ...state, fileSyaratPermohonan: action.payload };
    case "BERKAS_PERMOHONAN":
      return { ...state, berkasPermohonan: action.payload };
    case "STATUS_BERKAS_PERMOHONAN":
      return { ...state, statusBerkasPermohonan: action.payload };
    case "MODAL_PERMOHONAN_VALIDASI":
      return { ...state, modalPermohonanValidasi: action.payload };
    default:
      return state;
  }
};

export default permohonanReducer;
