import { http } from "src/config";
import { FUNCDateToString, FUNCNumberFormat } from "src/config/function";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setDataPengukuran = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_PENGUKURAN" });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch(setFormPengukuran("IdPermohonan", data.Data.IdPermohonan));
          dispatch(setFormPengukuran("NoPermohonan", data.Data.NoPermohonan));
          dispatch(setFormPengukuran("Nama", data.Data.Nama));
          dispatch(setFormPengukuran("Email", data.Data.Email));
          dispatch(
            setFormPengukuran("JenisPermohonan", data.Data.JenisPermohonan.Nama)
          );
          dispatch(
            setFormPengukuran("AlamatBidangTanah", data.Data.AlamatBidangTanah)
          );
          dispatch(
            setFormPengukuran(
              "JarakLokasiBidangTanah",
              data.Data.JarakLokasiBidangTanah
            )
          );
          dispatch(setFormPengukuran("LuasTanah", data.Data.LuasTanah));
          dispatch(setFormPengukuran("Latitude", data.Data.Latitude));
          dispatch(setFormPengukuran("Longitude", data.Data.Longitude));
          dispatch(setFormPengukuran("Wilker", data.Data.Wilker));
          dispatch(setFormPengukuran("Kjsb", data.Data.Kjsb.Nama));
          dispatch(
            setFormPengukuran(
              "JadwalUkur",
              data.Data.JadwalUkur && new Date(data.Data.JadwalUkur)
            )
          );

          //   dispatch(setFormPengukuran("IdPermohonan", data.Data.IdPermohonan));
          //   dispatch(setFormPengukuran("NoPermohonan", data.Data.NoPermohonan));
          //   dispatch(setFormPengukuran("Nama", data.Data.Nama));
          //   dispatch(setFormPengukuran("Email", data.Data.Email));
          dispatch(
            setDataPermohonanOutputPengukurans(data.Data.JenisPermohonan.Id)
          );
        } else {
          window.location.replace("/#/pengukuran");
        }
      });
  };
};
export const setDataPermohonanOutputPengukurans = (idJenisPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanOutputPengukurans?IdJenisPermohonan=${idJenisPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "FILE_SYARAT_PENGUKURAN", payload: data.Data });
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};

export const setFormPengukuran = (formType, formValue) => {
  return { type: "FORM_PENGUKURAN", formType, formValue };
};

export const apiProsesPengukuran = (iData, history) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("TanggalUkur", FUNCDateToString(iData.TanggalUkur));
    // fd.append("JamUkur", iData.JamUkur);
    fd.append("JamUkur", "00:00:00");
    fd.append("Catatan", iData.Catatan);

    if (iData.Files !== "") {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`Files[${i}].IdFile`, v.IdFile);
          fd.append(`Files[${i}].FileUpload`, v.FileUpload);
        }
      });
    }

    http.post(`${URLSVC}/Pelayanans/ProsesPengukuranWeb`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil simpan data`,
          "success"
        ).then(function () {
          history.goBack();
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
