const initialState = {
    dataBanners: [],
    dataBanner: null,
    modalBanner: false,
    formBanner: {
        IdMobileBanner:'',
        Description:'',
        MinTanggalTayang:'',
        MaxTanggalTayang:'',
        FileUpload:'',
        Status:'',
    },
    fileImageBannerPreview: '',
}

const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_BANNERS':
            return { ...state, dataBanners: action.payload }
        case 'DATA_BANNER':
            return { ...state, dataBanner: action.payload }
        case 'FORM_BANNER':
            return {
                ...state,
                formBanner: {
                    ...state.formBanner,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_BANNER':
            return { ...state, formBanner: initialState.formBanner }
        case 'MODAL_BANNER':
            return { ...state, modalBanner: action.payload }
        case 'FILE_IMAGE_BANNER_PREVIEW':
            return { ...state, fileImageBannerPreview: action.payload }
        default:
            return state
    }
}

export default bannerReducer;