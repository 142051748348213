import { http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const setDataDesas = (idKecamatan) => {
    loadingBar(true);
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetDesas?IdKecamatan=${idKecamatan}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({ type: 'DATA_DESAS', payload: data.Data });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}
export const setDataDesa = (idDesa) => {
    loadingBar(true);
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetDesa?IdDesa=${idDesa}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch(setFormDesa('Kode', data.Data.Kode));
                dispatch(setFormDesa('Nama', data.Data.Nama));
                dispatch(setFormDesa('KodePos', data.Data.KodePos));
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const setFormDesa = (formType, formValue) => {
    return {type: 'FORM_DESA', formType, formValue}
}

export const apiAddDesa = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdKecamatan', iData.IdKecamatan);
        fd.append('Kode', iData.Kode);
        fd.append('Nama', iData.Nama);
        fd.append('KodePos', iData.KodePos);

        http.post(`${URLSVC}/Webs/AddDesa`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil menambah data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_DESA', payload: false });
                    dispatch(setDataDesas(iData.IdKecamatan));
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiEditDesa = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdKecamatan', iData.IdKecamatan);
        fd.append('IdDesa', iData.Kode);
        fd.append('Kode', iData.Kode);
        fd.append('Nama', iData.Nama);
        fd.append('KodePos', iData.KodePos);

        http.post(`${URLSVC}/Webs/EditDesa`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil memperbarui data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_DESA', payload: false });
                    dispatch(setDataDesas(iData.IdKecamatan));
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiDeleteDesa = (idKec, idDesa, nama) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan desa (${nama})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);
                http.post(`${URLSVC}/Webs/DeleteDesa?IdDesa=${idDesa}`).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.IsSuccess) {
                        Swal.fire('Berhasil', `Anda berhasil hapus desa (${nama})`, 'success').then(function() {
                            dispatch(setDataDesas(idKec));
                        });
                    } else {
                        Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
                    }
                })
            }
        })
    }
}