const initialState = {
  dataBiayaJadwalPermohonans: [],
  modalBiayaJadwalPermohonan: false,
  formBiayaJadwalPermohonan: {
    NoPermohonan: "",
    IdJenisPermohonan: "",
    Nama: "",
    Email: "",
    JadwalUkur: "",
    IdSurveyor: "",
    RpPnbp: 0,
    JarakKm: "",
    RpAkomodasi: "",
    RpTranspotasi: 0,
    RpTotalAkomodasi: 0,
    RpUkur: 0,
    RpTotal: 0,
    NoSuratTugas: "",
    RpLayanan: 0,
    RpPpn: 0,

    JenisPermohonan: "",
    AlamatBidangTanah: "",
    JarakLokasiBidangTanah: "",
    LuasTanah: "",
    Latitude: "",
    Longitude: "",
    Wilker: {},
    Kjsb: "",
    IdJenisProperty: "",
    JumlahTim: "",
    JumlahHari: "",
    RpLainnya: "",
    JamUkurAwal: "",
    JamUkurAkhir: "",
    RpDetails: [],
  },
  dataBiayaJadwalSurveyor: [],
};

const hitungBiayaDanPenjadwalanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_BIAYA_JADWAL_PERMOHONAN":
      return { ...state, dataBiayaJadwalPermohonans: action.payload };
    case "FORM_BIAYA_JADWAL_PERMOHONAN":
      return {
        ...state,
        formBiayaJadwalPermohonan: {
          ...state.formBiayaJadwalPermohonan,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_BIAYA_JADWAL_PERMOHONAN":
      return {
        ...state,
        formBiayaJadwalPermohonan: initialState.formBiayaJadwalPermohonan,
      };
    case "MODAL_BIAYA_JADWAL_PERMOHONAN":
      return { ...state, modalBiayaJadwalPermohonan: action.payload };
    case "DATA_BIAYA_JADWAL_SURVEYORS":
      return { ...state, dataBiayaJadwalSurveyor: action.payload };
    default:
      return state;
  }
};

export default hitungBiayaDanPenjadwalanReducer;
