import Swal from "sweetalert2";

export const FUNCArraySelectId = (data) => {
  var array = [];
  array.push({
    value: "",
    label: "SELECT ONE",
  });
  data.map((v, i) => {
    array.push({
      value: v.Id,
      label: v.Nama,
    });
  });
  return array;
};

export const FUNCArraySelectKode = (data) => {
  var array = [];
  data.map((v, i) => {
    array.push({
      value: v.Kode,
      label: v.Nama,
    });
  });
  return array;
};

export const FUNCArraySelectRole = (data) => {
  var array = [];
  data.map((v, i) => {
    array.push({
      value: v.IdRole,
      label: v.RoleName,
    });
  });
  return array;
};

export const FUNCDateToString = (date) => {
  var Date = date.getDate();
  var Month = date.getMonth() + 1;
  var Year = date.getFullYear();
  if (Date < 10) Date = "0" + Date;
  if (Month < 10) Month = "0" + Month;
  return Date + "-" + Month + "-" + Year;
};

export const FUNCMonthToString = (date) => {
  var Month = date.getMonth() + 1;
  var Year = date.getFullYear();
  if (Month < 10) Month = "0" + Month;
  return Month + "-" + Year;
};

export const FUNCYearToString = (date) => {
  var Year = date.getFullYear();
  return Year;
};

export const FUNCIndoDate = (date) => {
  var SplitTanggal = date.split("-");
  var Hari = SplitTanggal[0];
  var Bulan = SplitTanggal[1];
  var Tahun = SplitTanggal[2];

  var ArrayBulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  if (Bulan < 10) {
    Bulan = Bulan.replace("0", "");
  }

  return Hari + " " + ArrayBulan[Bulan - 1] + " " + Tahun;
};

export const FUNCSetFullName = (FirstName, MiddleName, LastName) => {
  var satu = FirstName || " ";
  var dua = MiddleName || " ";
  var tiga = LastName || " ";
  return satu + " " + dua + " " + tiga;
};

export const FUNCValidateUploadFileSize = (
  fi,
  maxSize = 2048,
  strMaxSize = "2MB"
) => {
  if (fi.files.length > 0) {
    for (var i = 0; i <= fi.files.length - 1; i++) {
      const fsize = fi.files.item(i).size;
      const file = Math.round(fsize / 1024);
      if (file >= maxSize) {
        Swal.fire(
          "Gagal",
          `Ukuran file terlalu besar, batas ukuran ${strMaxSize}`,
          "error"
        );
        fi.value = "";
        return null;
      }
    }
  }
};

export const FUNCValidateUploadFileExtension = (
  oInput,
  _validFileExtensions = [".jpg", ".jpeg", ".pdf", ".png"]
) => {
  var sFileName = oInput.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    var msgExtension = "";
    for (var j = 0; j < _validFileExtensions.length; j++) {
      msgExtension += _validFileExtensions[j] + " ";
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }

    if (!blnValid) {
      Swal.fire(
        "Gagal",
        `Ekstensi file tidak didukung! <br /> format harus ${msgExtension}`,
        "error"
      );
      oInput.value = "";
      return false;
    }
  }
};
export const FUNCNumberFormat = (str) => {
  if (str !== 0) {
    while (str.search(",") >= 0) {
      str = (str + "").replace(",", "");
    }
    return str;
  } else {
    return str;
  }
};

export const FUNCDateDmytoYmd = (date) => {
  var b = date.split(/\D/);
  return b.reverse().join("-");
};
