const initialState = {
  dataPengolahans: [],
  modalPengolahan: false,
  formPengolahan: {
    IdPermohonan: "",
    TanggalBayar: "",
    RpBayar: "",
    NoPermohonan: "",
    Nama: "",
    Email: "",
  },
};

const pengolahanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_PENGOLAHANS":
      return { ...state, dataPengolahans: action.payload };
    case "FORM_PENGOLAHAN":
      return {
        ...state,
        formPengolahan: {
          ...state.formPengolahan,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_PENGOLAHAN":
      return {
        ...state,
        formPengolahan: initialState.formPengolahan,
      };
    case "MODAL_PENGOLAHAN":
      return { ...state, modalPengolahan: action.payload };
    default:
      return state;
  }
};

export default pengolahanReducer;
