const initialState = {
  dataRegSurveyorInfo: false,
};

const daftarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_REG_SURVEYOR_INFO":
      return { ...state, dataRegSurveyorInfo: action.payload };
    default:
      return state;
  }
};

export default daftarReducer;
