import { FUNCDateToString, http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

// export const setFormDaftarKjsb = (formType, formValue) => {
//     return {type: 'FORM_DAFTAR_KJSB', formType, formValue}
// }
// export const setFormDaftarSkb = (formType, formValue) => {
//     return {type: 'FORM_DAFTAR_SKB', formType, formValue}
// }

export const apiRegKjsb = (iData, files) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("Nama", iData.Nama.toUpperCase());
    fd.append("Npwp", iData.Npwp);
    fd.append("NoKontak", iData.NoKontak);
    fd.append("Email", iData.Email.toLowerCase());
    fd.append("Alamat", iData.Alamat);
    fd.append("NoIzinMenteri", iData.NoIzinMenteri);
    fd.append("IdPimpinan", iData.IdPimpinan.value);
    fd.append("IdWilker", iData.IdWilker.value);
    fd.append("Lon", iData.Longitude);
    fd.append("Lat", iData.Latitude);
    fd.append("NoAkte", iData.NoAkte);
    fd.append("NoAktePerubahan", iData.NoAktePerubahan);

    iData.Pendiris.map((v, i) => {
      fd.append(`Pendiris[${i}]`, v.value);
    });
    if (iData.Files === undefined) {
      Swal.fire("Gagal", "file wajib diupload semua!", "error");
      return null;
    }
    if (iData.Files.length === 0) {
      Swal.fire("Gagal", "file wajib diupload semua!", "error");
      return null;
    }

    if (iData.Files !== "") {
      iData.Files.map((v, i) => {
        if (v.Kode !== null) {
          fd.append(`Files[${i}].IdFile`, v.IdFile);
          fd.append(`Files[${i}].FileUpload`, v.FileUpload);
        }
      });
    }
    http
      .post(`${URLSVC}/Registrasis/RegKjsb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Nomor Registrasi : ${data.Data} <br /> Informasi selanjutnya silahkan cek email!`,
            "success"
          ).then(() => window.location.reload());
          dispatch({ type: "RESET_FORM_KJSKB" });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiRegSkb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("FirstName", iData.FirstName.toUpperCase());
    fd.append(
      "MiddleName",
      iData.MiddleName === "null" ? "" : iData.MiddleName
    );
    fd.append("LastName", iData.LastName === "null" ? "" : iData.LastName);
    fd.append("Address", iData.Address);
    fd.append("PhoneNumber", iData.PhoneNumber);
    fd.append("MobileNumber", iData.MobileNumber);
    fd.append("Email", iData.Email);
    fd.append("NoLisensi", iData.NoLisensi);
    fd.append("NoKtp", iData.NoKtp);
    fd.append("TanggalLisensi", FUNCDateToString(iData.TanggalLisensi));
    fd.append("IdDesa", iData.SelectDesa.value);
    fd.append("IdJenisSurveyor", iData.IdJenisSurveyor.value);
    if (iData.IdKjsb !== undefined || iData.IdKjsb !== "") {
      fd.append("IdKjsb", iData.IdKjsb.value);
    }
    if (iData.IdWilker !== undefined || iData.IdWilker !== "") {
      fd.append("IdWilker", iData.IdWilker.value);
    }

    if (iData.Files !== "") {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`Files[${i}].IdFile`, v.IdFile);
          fd.append(`Files[${i}].FileUpload`, v.FileUpload);
        }
      });
    }

    http
      .post(`${URLSVC}/Registrasis/RegSkb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Nomor Registrasi : ${data.Data} <br /> Informasi selanjutnya silahkan cek email!`,
            "success"
          ).then(() => window.location.reload());
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiUploadPayKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    if (iData.BuktiBayar === "" || iData.BuktiBayar === undefined) {
      Swal.fire("Gagal", `Bukti bayar wajib di upload!`, "info");
      return;
    }

    fd.append("NoReg", iData.NoReg);
    fd.append("FileUpload", iData.BuktiBayar);

    http
      .post(`${URLSVC}/Registrasis/UploadPayKjsb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Upload bukti bayar sedang dalam proses (max 1x24jam)`,
            "success"
          ).then(() => (window.location = "/#/login"));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
export const apiUploadPaySkb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    if (iData.BuktiBayar === "" || iData.BuktiBayar === undefined) {
      Swal.fire("Gagal", `Bukti bayar wajib di upload!`, "info");
      return;
    }

    fd.append("NoReg", iData.NoReg);
    fd.append("FileUpload", iData.BuktiBayar);

    http
      .post(`${URLSVC}/Registrasis/UploadPaySkb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Upload bukti bayar sedang dalam proses (max 1x24jam)`,
            "success"
          ).then(() => (window.location = "/"));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiGetRegSurveyorInfo = (noLisensi) => {
  return (dispatch) => {
    loadingBar(true);
    http
      .get(`${URLSVC}/Webs/GetRegSurveyorInfo?noLisensi=${noLisensi}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_REG_SURVEYOR_INFO", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
