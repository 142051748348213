const initialState = {
  dataKjskbs: [],
  dataRegKjsbs: [],
  dataRegKjsb: false,
  dataAktifSurveyors: [],
  dataPengalamanKjsbs: [],
  dataPengalamanKjsb: false,
  modalPengalamanKjsb: false,
  modalKjskb: false,
  modalKjskbBerkas: false,
  modalRegKjsbData: false,
  modalRegKjsbPay: false,
  modalRegKjsbBerkas: false,
  formKjskb: {
    IdKjsb: "",
    KdKjsb: "",
    Nama: "",
    IdPimpinan: "",
    Npwp: "",
    NoKontak: "",
    Email: "",
    Alamat: "",
    NoIzinMenteri: "",
    IdWilker: "",
    Pendiris: [],
    Files: [],
    Anggotas: [],
    Latitude: "",
    Longitude: "",
    NoAkte: "",
    NoAktePerubahan: "",
    IdRegKjsb: "",
    NoRegKjsb: "",
    Catatan: "",
    BuktiBayar: "ok",
    FileDownloads: [],
  },
  fileSkMenteriKjskbPreview: "",
  fileNpwpKjskbPreview: "",

  fileKjskb: [],

  dataAnggotaKjskbs: [],
  dataMasterKjsbDataSb: [],
  dataCountRegKjsb: {
    Registrasi: 0,
    MenungguPembayaran: 0,
    UploadBuktiBayar: 0,
    Terdaftar: 0,
  },
  modalAnggotaKjskb: false,
};

const kjskbReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_KJSKBS":
      return { ...state, dataKjskbs: action.payload };
    case "DATA_REG_KJSBS":
      return { ...state, dataRegKjsbs: action.payload };
    case "DATA_REG_KJSB":
      return { ...state, dataRegKjsb: action.payload };
    case "DATA_AKTIF_SURVEYORS":
      return { ...state, dataAktifSurveyors: action.payload };
    case "DATA_COUNT_REG_KJSB":
      return { ...state, dataCountRegKjsb: action.payload };
    case "FORM_KJSKB":
      return {
        ...state,
        formKjskb: {
          ...state.formKjskb,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_KJSKB":
      return { ...state, formKjskb: initialState.formKjskb };
    case "MODAL_KJSKB":
      return { ...state, modalKjskb: action.payload };
    case "MODAL_KJSKB_BERKAS":
      return { ...state, modalKjskbBerkas: action.payload };
    case "FILE_SK_MENTERI_KJSKB_PREVIEW":
      return { ...state, fileSkMenteriKjskbPreview: action.payload };
    case "FILE_NPWP_KJSKB_PREVIEW":
      return { ...state, fileNpwpKjskbPreview: action.payload };
    case "FILE_KJSKB":
      return { ...state, fileKjskb: action.payload };

    case "DATA_ANGGOTA_KJSKBS":
      return { ...state, dataAnggotaKjskbs: action.payload };
    case "DATA_MASTER_KJSB_DATA_SB":
      return { ...state, dataMasterKjsbDataSb: action.payload };
    case "DATA_PENGALAMAN_KJSBS":
      return { ...state, dataPengalamanKjsbs: action.payload };
    case "DATA_PENGALAMAN_KJSB":
      return { ...state, dataPengalamanKjsb: action.payload };
    case "MODAL_PENGALAMAN_KJSB":
      return { ...state, modalPengalamanKjsb: action.payload };
    case "MODAL_ANGGOTA_KJSKB":
      return { ...state, modalAnggotaKjskb: action.payload };
    case "MODAL_REG_KJSB_DATA":
      return { ...state, modalRegKjsbData: action.payload };
    case "MODAL_REG_KJSB_PAY":
      return { ...state, modalRegKjsbPay: action.payload };
    case "MODAL_REG_KJSB_BERKAS":
      return { ...state, modalRegKjsbBerkas: action.payload };
    default:
      return state;
  }
};

export default kjskbReducer;
