import { http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const setDataMenus = (idAppl) => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetApplTasks?IdAppl=1`).then((res) => {
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({ type: 'DATA_MENUS', payload: data.Data });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const setDataMenu = (idApplTask) => {
    return (dispatch) => {
        loadingBar(true);
        dispatch({ type: 'RESET_FORM_JENIS_BANTUAN' });
        http.get(`${URLSVC}/Webs/GetApplTasks?IdApplTask=${idApplTask}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({ type: 'MODAL_MENU', payload: true })
                dispatch(setFormMenu('IdApplTask', data.Data.IdApplTask))
                dispatch(setFormMenu('ApplName', data.Data.ApplName))
                dispatch(setFormMenu('IdApplTaskParent', data.Data.IdApplTaskParent))
                dispatch(setFormMenu('ApplTaskName', data.Data.ApplTaskName))
                dispatch(setFormMenu('IdAppl', data.Data.IdAppl))
                dispatch(setFormMenu('ControllerName', data.Data.ControllerName))
                dispatch(setFormMenu('ActionName', data.Data.ActionName))
                dispatch(setFormMenu('Description', data.Data.Description))
                dispatch(setFormMenu('IconName', data.Data.IconName))
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const setFormMenu = (formType, formValue) => {
    return {type: 'FORM_MENU', formType, formValue}
}

export const apiAddMenu = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdApplTaskParent', iData.IdApplTaskParent)
        fd.append('ApplTaskName', iData.ApplTaskName)
        fd.append('IdAppl', iData.IdAppl)
        fd.append('ControllerName', iData.ControllerName)
        fd.append('ActionName', iData.ActionName)
        fd.append('Description', iData.Description)
        fd.append('IconName', iData.IconName)

        http.post(`${URLSVC}/Webs/AddApplTask`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil menambah menu baru`, 'success');
                dispatch({ type: 'MODAL_MENU', payload: false });
                dispatch(setDataMenus());
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiEditApplTask = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdApplTaskParent', iData.IdApplTaskParent)
        fd.append('ApplTaskName', iData.ApplTaskName)
        fd.append('IdAppl', iData.IdAppl)
        fd.append('ControllerName', iData.ControllerName)
        fd.append('ActionName', iData.ActionName)
        fd.append('Description', iData.Description)
        fd.append('IconName', iData.IconName)

        http.post(`${URLSVC}/Webs/EditApplTask`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil memperbarui menu`, 'success');
                dispatch({ type: 'MODAL_MENU', payload: false });
                dispatch(setDataMenus());
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiDeleteApplTask = (idApplTask, keterangan) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan hapus menu (${keterangan})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);
                http.get(`${URLSVC}/Webs/DeleteApplTask?IdApplTask=${idApplTask}`).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.IsSuccess) {
                        Swal.fire('Berhasil', `Anda berhasil hapus menu (${keterangan})`, 'success');
                        dispatch(setDataMenus())
                    } else {
                        Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
                    }
                })
            }
        })
    }
}