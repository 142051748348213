const initialState = {
  dataLegalisasiGus: [],
  modalLegalisasiGu: false,
  formLegalisasiGu: {
    IdPermohonan: "",
    NoPermohonan: "",
    Nama: "",
    Email: "",
    JenisPermohonan: "",
    AlamatBidangTanah: "",
    JarakLokasiBidangTanah: "",
    LuasTanah: "",
    Latitude: "",
    Longitude: "",
    Wilker: {},
    Kjsb: "",
  },
  fileSyaratLegalisasiGu: [],
};

const legalisasiGuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_LEGALISASI_GUS":
      return { ...state, dataLegalisasiGus: action.payload };
    case "FORM_LEGALISASI_GU":
      return {
        ...state,
        formLegalisasiGu: {
          ...state.formLegalisasiGu,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_LEGALISASI_GU":
      return {
        ...state,
        formLegalisasiGu: initialState.formLegalisasiGu,
      };
    case "MODAL_LEGALISASI_GU":
      return { ...state, modalLegalisasiGu: action.payload };
    case "FILE_SYARAT_LEGALISASI_GU":
      return { ...state, fileSyaratLegalisasiGu: action.payload };
    default:
      return state;
  }
};

export default legalisasiGuReducer;
