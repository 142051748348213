import { FUNCDateToString, http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "src/config/redux/action";
import axios from "axios";

export const setFormPerpanjangMemberKjsb = (formType, formValue) => {
  return { type: "FORM_PERPANJANG_MEMBER_KJSB", formType, formValue };
};

export const setDataPerpanjangMemberKjsbs = () => {
  return (dispatch) => {
    loadingBar(true);
    http.get(`${URLSVC}/Webs/GetPerpanjangMemberKjsbs`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({
          type: "DATA_PERPANJANG_MEMBER_KJSBS",
          payload: data.Data,
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataPerpanjangMemberKjsb = (id, setValue = null) => {
  return (dispatch) => {
    loadingBar(true);
    http
      .get(`${URLSVC}/Webs/GetPerpanjangMemberKjsb?IdMemberUpdate=${id}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch(setFormPerpanjangMemberKjsb("IdMemberUpdate", id));
          dispatch(setFormPerpanjangMemberKjsb("Nama", data.Data.Nama));
          dispatch(
            setFormPerpanjangMemberKjsb("NoAnggota", data.Data.NoAnggota)
          );
          dispatch(setFormPerpanjangMemberKjsb("Durasi", data.Data.Durasi));
          dispatch(
            setFormPerpanjangMemberKjsb("JumlahBayar", data.Data.JumlahBayar)
          );
          dispatch(
            setFormPerpanjangMemberKjsb(
              "TanggalPengajuan",
              data.Data.TanggalPengajuan
            )
          );
          dispatch(
            setFormPerpanjangMemberKjsb(
              "TanggalRespon",
              data.Data.TanggalRespon
            )
          );
          dispatch(setFormPerpanjangMemberKjsb("Catatan", data.Data.Catatan));
          dispatch(setFormPerpanjangMemberKjsb("Status", data.Data.Status));
          dispatch(
            setFormPerpanjangMemberKjsb("FileDownloads", data.Data.Files)
          );
          dispatch({
            type: "DATA_PERPANJANG_MEMBER_KJSB",
            payload: data.Data,
          });
          dispatch({
            type: "MODAL_PERPANJANG_MEMBER_KJSB",
            payload: true,
          });
          if (setValue !== null) {
            setValue("IdMemberUpdate", data.Data.IdMemberUpdate);
            setValue("Nama", data.Data.Nama);
          }
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiPerpanjangMemberKjsbWeb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("NoRegKjsb", iData.NoRegKjsb);
    fd.append("Durasi", iData.Durasi.value);
    fd.append("JumlahBayar", iData.JumlahBayar);
    if (iData.Files.length !== 0) {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`FileBerkas[${i}].IdFile`, v.IdFile);
          fd.append(`FileBerkas[${i}].FileUpload`, v.FileUpload);
        }
      });
    } else {
      loadingBar(false);
      Swal.fire("Gagal", `File berkas wajib di upload`, "error");
      return;
    }

    axios.post(`${URLSVC}/Webs/PerpanjangMemberKjsbWeb`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Pengajuan untuk perpanjang masa aktif kartu anggota berhasil dilakukan, silahkan tunggu konfirmasi dari operator!`,
          "success"
        ).then(function () {
          window.location.reload();
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiPerpanjangMemberKjsb = (iData, reset) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("Durasi", iData.Durasi.value);
    fd.append("JumlahBayar", iData.JumlahBayar);
    if (iData.Files.length !== 0) {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`FileBerkas[${i}].IdFile`, v.IdFile);
          fd.append(`FileBerkas[${i}].FileUpload`, v.FileUpload);
        }
      });
    } else {
      loadingBar(false);
      Swal.fire("Gagal", `File berkas wajib di upload`, "error");
      return;
    }

    http.post(`${URLSVC}/Webs/PerpanjangMemberKjsb`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Pengajuan untuk perpanjang masa aktif kartu anggota berhasil dilakukan, silahkan tunggu konfirmasi dari operator!`,
          "success"
        ).then(function () {
          dispatch(setDataPerpanjangMemberKjsbs());
        });

        //reset value
        reset({ Durasi: "" });
        reset({ JumlahBayar: "" });
        iData.Files.map((v, i) => {
          var idFile = document.getElementById(`file${i}`);
          idFile.value = idFile.defaultValue;
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiBatalPerpanjangMemberKjsb = (id) => {
  return (dispatch) => {
    loadingBar(true);
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan membatalkannya!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(`${URLSVC}/Webs/BatalPerpanjangMemberKjsb?IdMemberUpdate=${id}`)
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda telah membatalkan perpanjang kartu anggota`,
                "success"
              ).then(function () {
                dispatch(setDataPerpanjangMemberKjsbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiApprovePerpanjangMemberKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan perpanjang masa aktif anggota KJSB (${iData.Nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(
            `${URLSVC}/Webs/ApprovePerpanjangMemberKjsb?IdMemberUpdate=${iData.IdMemberUpdate}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil perpanjang masa aktif anggota KJSB (${iData.Nama})`,
                "success"
              ).then(function () {
                dispatch({
                  type: "MODAL_PERPANJANG_MEMBER_KJSB",
                  payload: false,
                });
                dispatch(setDataPerpanjangMemberKjsbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiRejectPerpanjangMemberKjsb = (iData) => {
  loadingBar(true);
  if (iData.Catatan === "") {
    Swal.fire("Gagal", `Catatan harus diisi`, "error");
    return;
  }
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan tolak perpanjang masa aktif anggota KJSB (${iData.Nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(
            `${URLSVC}/Webs/RejectPerpanjangMemberKjsb?IdMemberUpdate=${iData.IdMemberUpdate}&Catatan=${iData.Catatan}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil tolak perpanjang masa aktif anggota KJSB (${iData.Nama})`,
                "success"
              ).then(function () {
                dispatch({
                  type: "MODAL_PERPANJANG_MEMBER_KJSB",
                  payload: false,
                });
                dispatch(setDataPerpanjangMemberKjsbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
