const initialState = {
    dataKabupatens: [],
    modalKabupaten: false,
    formKabupaten: {
        IdProvinsi: '',
        Kode: '',
        Nama: ''
    },
}

const wilKabupatenReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_KABUPATENS':
            return { ...state, dataKabupatens: action.payload }
        case 'FORM_KABUPATEN':
            return {
                ...state,
                formKabupaten: {
                    ...state.formKabupaten,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_KABUPATEN':
            return { ...state, formKabupaten: initialState.formKabupaten }
        case 'MODAL_KABUPATEN':
            return { ...state, modalKabupaten: action.payload }
        default:
            return state
    }
}

export default wilKabupatenReducer;