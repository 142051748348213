import { http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const setDataKecamatans = (idKabupaten) => {
    loadingBar(true);
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetKecamatans?IdKabupaten=${idKabupaten}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({ type: 'DATA_KECAMATANS', payload: data.Data });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}
export const setDataKecamatan = (idKecamatan) => {
    loadingBar(true);
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetKecamatan?IdKecamatan=${idKecamatan}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch(setFormKecamatan('Kode', data.Data.Kode));
                dispatch(setFormKecamatan('Nama', data.Data.Nama));
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const setFormKecamatan = (formType, formValue) => {
    return {type: 'FORM_KECAMATAN', formType, formValue}
}

export const apiAddKecamatan = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdKabupaten', iData.IdKabupaten);
        fd.append('Kode', iData.Kode);
        fd.append('Nama', iData.Nama);

        http.post(`${URLSVC}/Webs/AddKecamatan`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil menambah data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_KECAMATAN', payload: false });
                    dispatch(setDataKecamatans(iData.IdKabupaten));
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiEditKecamatan = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdKecamatan', iData.Kode);
        fd.append('Kode', iData.Kode);
        fd.append('Nama', iData.Nama);
        fd.append('IdKabupaten', iData.IdKabupaten);

        http.post(`${URLSVC}/Webs/EditKecamatan`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil memperbarui data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_KECAMATAN', payload: false });
                    dispatch(setDataKecamatans(iData.IdKabupaten));
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiDeleteKecamatan = (idKab, idKecamatan, nama) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan hapus kecamatan (${nama})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);
                http.post(`${URLSVC}/Webs/DeleteKecamatan?IdKecamatan=${idKecamatan}`).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.IsSuccess) {
                        Swal.fire('Berhasil', `Anda berhasil hapus kecamatan (${nama})`, 'success').then(function() {
                            dispatch(setDataKecamatan(idKab));
                        });
                    } else {
                        Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
                    }
                })
            }
        })
    }
}