const initialState = {
  dataPindahKjsbSatuWilkers: [],
  formPindahKjsbSatuWilker: {
    TanggalLisensiBaru: null,
    FileDownloads: [],
    Catatan: "",
  },
  modalPindahKjsbSatuWilker: false,
};

const pindahKjsbSatuWilkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_PINDAH_KJSB_SATU_WILKERS":
      return { ...state, dataPindahKjsbSatuWilkers: action.payload };
    case "FORM_PINDAH_KJSB_SATU_WILKER":
      return {
        ...state,
        formPindahKjsbSatuWilker: {
          ...state.formPindahKjsbSatuWilker,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_PINDAH_KJSB_SATU_WILKER":
      return {
        ...state,
        formPindahKjsbSatuWilker: initialState.formPindahKjsbSatuWilker,
      };
    case "MODAL_PINDAH_KJSB_SATU_WILKER":
      return { ...state, modalPindahKjsbSatuWilker: action.payload };
    default:
      return state;
  }
};

export default pindahKjsbSatuWilkerReducer;
