import { http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { setDataComboKjsbByProv } from "../comboAction";
import {
  setDataComboKab,
  setDataComboKec,
  setDataComboDesa,
} from "../comboAction";
import { loadingBar } from "../globalAction";

export const setDataSurveyors = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetAllSurveyors`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_SURVEYORS", payload: data.Data });
      } else {
        // Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
      }
    });
  };
};

export const setDataSurveyor = (idSurveyor) => {
  loadingBar(true);
  return (dispatch) => {
    http
      .get(`${URLSVC}/Webs/GetSurveyor?IdSurveyor=${idSurveyor}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch(setFormSurveyor("IdSurveyor", idSurveyor));
          dispatch(setFormSurveyor("FirstName", data.Data.FirstName));
          dispatch(setFormSurveyor("MiddleName", data.Data.MiddleName || ""));
          dispatch(setFormSurveyor("LastName", data.Data.LastName || ""));
          dispatch(setFormSurveyor("Address", data.Data.Address));
          dispatch(setFormSurveyor("PhoneNumber", data.Data.PhoneNumber));
          dispatch(setFormSurveyor("MobileNumber", data.Data.MobileNumber));
          dispatch(setFormSurveyor("Email", data.Data.Email));
          dispatch(setFormSurveyor("NoKtp", data.Data.NoKtp));
          dispatch(setFormSurveyor("NoLisensi", data.Data.NoLisensi));
          dispatch(setFormSurveyor("KartuAnggota", data.Data.KartuAnggota));
          dispatch(setFormSurveyor("FileDownloads", data.Data.Files));
          dispatch(setFormSurveyor("SerkomFile", data.Data.SerkomFile));
          dispatch(setFormSurveyor("IsValidSerkomFile", data.Data.IsValidSerkomFile));

          const SELECT = {
            IdProvinsi: {
              value: data.Data.Provinsi.Id,
              label: data.Data.Provinsi.Nama,
            },
            IdKabupaten: {
              value: data.Data.Kabupaten.Id,
              label: data.Data.Kabupaten.Nama,
            },
            IdKecamatan: {
              value: data.Data.Kecamatan.Id,
              label: data.Data.Kecamatan.Nama,
            },
            IdDesa: {
              value: data.Data.Desa.Id,
              label: data.Data.Desa.Nama,
            },
            IdJenisSurveyor: {
              value: data.Data.JenisSurveyor.Id,
              label: data.Data.JenisSurveyor.Nama,
            },
            IdWilker: {
              value: data.Data.Wilker.Id,
              label: data.Data.Wilker.Nama,
            },
            IdKjsb: {
              value: data.Data.Kjsb?.Id,
              label: data.Data.Kjsb?.Nama,
            },
          };

          dispatch(
            setDataComboKjsbByProv("DATA_COMBO_KJSB", data.Data.Wilker.Id)
          );

          // if (data.Data.Kjsb !== null)

          dispatch(setFormSurveyor("SelectProv", SELECT.IdProvinsi));
          dispatch(setFormSurveyor("SelectKab", SELECT.IdKabupaten));
          dispatch(setFormSurveyor("SelectKec", SELECT.IdKecamatan));
          dispatch(setFormSurveyor("SelectDesa", SELECT.IdDesa));
          dispatch(setFormSurveyor("IdWilker", SELECT.IdWilker));
          if (data.Data.Kjsb !== null)
            dispatch(setFormSurveyor("IdKjsb", SELECT.IdKjsb));

          dispatch(setDataComboKab("DATA_COMBO_KAB", data.Data.Provinsi.Id));
          dispatch(setDataComboKec("DATA_COMBO_KEC", data.Data.Kabupaten.Id));
          dispatch(setDataComboDesa("DATA_COMBO_DESA", data.Data.Kecamatan.Id));

          dispatch(setFormSurveyor("IdJenisSurveyor", SELECT.IdJenisSurveyor));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataSurveyorByUser = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetSurveyorByUser`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch(setFormSurveyor("FirstName", data.Data.FirstName));
        dispatch(setFormSurveyor("MiddleName", data.Data.MiddleName || ""));
        dispatch(setFormSurveyor("LastName", data.Data.LastName || ""));
        dispatch(setFormSurveyor("Address", data.Data.Address));
        dispatch(setFormSurveyor("PhoneNumber", data.Data.PhoneNumber));
        dispatch(setFormSurveyor("MobileNumber", data.Data.MobileNumber));
        dispatch(setFormSurveyor("Email", data.Data.Email));
        dispatch(setFormSurveyor("NoKtp", data.Data.NoKtp));
        dispatch(setFormSurveyor("NoLisensi", data.Data.NoLisensi));
        dispatch(setFormSurveyor("TanggalLisensi", data.Data.TanggalLisensi));
        dispatch(setFormSurveyor("KartuAnggota", data.Data.KartuAnggota));
        dispatch(setFormSurveyor("FileDownloads", data.Data.Files));

        const SELECT = {
          IdProvinsi: {
            value: data.Data.Provinsi.Id,
            label: data.Data.Provinsi.Nama,
          },
          IdKabupaten: {
            value: data.Data.Kabupaten.Id,
            label: data.Data.Kabupaten.Nama,
          },
          IdKecamatan: {
            value: data.Data.Kecamatan.Id,
            label: data.Data.Kecamatan.Nama,
          },
          IdDesa: {
            value: data.Data.Desa.Id,
            label: data.Data.Desa.Nama,
          },
          IdJenisSurveyor: {
            value: data.Data.JenisSurveyor.Id,
            label: data.Data.JenisSurveyor.Nama,
          },
          IdWilker: {
            value: data.Data.Wilker.Id,
            label: data.Data.Wilker.Nama,
          },
          IdKjsb: {
            value: data.Data.Kjsb?.Id,
            label: data.Data.Kjsb?.Nama,
          },
        };
        dispatch(setFormSurveyor("SelectProv", SELECT.IdProvinsi));
        dispatch(setFormSurveyor("SelectKab", SELECT.IdKabupaten));
        dispatch(setFormSurveyor("SelectKec", SELECT.IdKecamatan));
        dispatch(setFormSurveyor("SelectDesa", SELECT.IdDesa));
        dispatch(setFormSurveyor("IdWilker", SELECT.IdWilker));
        dispatch(setFormSurveyor("IdKjsb", SELECT.IdKjsb));

        dispatch(setDataComboKab("DATA_COMBO_KAB", data.Data.Provinsi.Id));
        dispatch(setDataComboKec("DATA_COMBO_KEC", data.Data.Kabupaten.Id));
        dispatch(setDataComboDesa("DATA_COMBO_DESA", data.Data.Kecamatan.Id));

        dispatch(setFormSurveyor("IdJenisSurveyor", SELECT.IdJenisSurveyor));
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setFileSurveyor = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetJenisSurveyorFiles`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type, payload: data.Data });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataRegSkbs = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Registrasis/GetSkbs`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_REG_SKBS", payload: data.Data });
        let countReg = 0,
          countMenungguPem = 0,
          countUploadBayar = 0,
          countTerdaftar = 0;

        data.Data.map((v, i) => {
          if (v.Status === "Registrasi") countReg += 1;
          if (v.Status === "Verifikasi") countMenungguPem += 1;
          if (v.Status === "Upload Bukti Bayar") countUploadBayar += 1;
          if (v.Status === "Anggota MASKI") countTerdaftar += 1;
        });

        dispatch({
          type: "DATA_COUNT_REG_SKB",
          payload: {
            Registrasi: countReg,
            MenungguPembayaran: countMenungguPem,
            UploadBuktiBayar: countUploadBayar,
            Terdaftar: countTerdaftar,
          },
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataRegSkb = (idRegSurveyor) => {
  loadingBar(true);
  return (dispatch) => {
    http
      .get(`${URLSVC}/Registrasis/GetSkb?IdRegSurveyor=${idRegSurveyor}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_REG_SKB", payload: data.Data });
          dispatch({ type: "MODAL_REG_SURVEYOR_BERKAS", payload: true });
          dispatch(setFormSurveyor("IdRegSkb", idRegSurveyor));
          dispatch(setFormSurveyor("NoRegSkb", data.Data.NoReg));
          dispatch(setFormSurveyor("Email", data.Data.Email));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataSurveyorNoKjsb = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetSurveyorNoKjsbByWilkerByKorwil`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_SURVEYOR_NO_KJSBS", payload: data.Data });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setFormSurveyor = (formType, formValue) => {
  return { type: "FORM_SURVEYOR", formType, formValue };
};

export const apiAddSurveyor = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("FirstName", iData.FirstName);
    fd.append(
      "MiddleName",
      iData.MiddleName === "null" ? "" : iData.MiddleName
    );
    fd.append("LastName", iData.LastName === "null" ? "" : iData.LastName);
    fd.append("Address", iData.Address);
    fd.append("PhoneNumber", iData.PhoneNumber);
    fd.append("MobileNumber", iData.MobileNumber);
    fd.append("Email", iData.Email);
    fd.append("NoLisensi", iData.NoLisensi);
    fd.append("NoKtp", iData.NoKtp);
    fd.append("IdDesa", iData.SelectDesa.value);
    fd.append("IdJenisSurveyor", iData.IdJenisSurveyor.value);
    fd.append("IdWilker", iData.IdWilker.value);

    if (iData.Files !== "") {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`Files[${i}].IdFile`, v.IdFile);
          fd.append(`Files[${i}].FileUpload`, v.FileUpload);
        }
      });
    }

    http
      .post(`${URLSVC}/Webs/AddSurveyor`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil menambah data`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_SURVEYOR", payload: false });
            dispatch(setDataSurveyors());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiVerifikasiSerkom = (IdSurveyor,FirstName,IsValid) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan verifikasi Sertifikat Kompetensi surveyor atas nama ${FirstName}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("IdSurveyor", IdSurveyor);
        fd.append("StatusProses", IsValid);
        http
          .post(`${URLSVC}/Webs/VerifikasiSerkom`,fd)
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil verifikasi Sertifikat Kompetensi`,
                "success"
              ).then(function () {
                dispatch({ type: "MODAL_SURVEYOR_VERIFIKASI_SERKOM", payload: false });
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiEditSurveyor = (iData, files) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdSurveyor", iData.IdSurveyor);
    fd.append("FirstName", iData.FirstName);
    fd.append("MiddleName", iData.MiddleName || "");
    fd.append("LastName", iData.LastName || "");
    fd.append("Address", iData.Address);
    fd.append("PhoneNumber", iData.PhoneNumber);
    fd.append("MobileNumber", iData.MobileNumber);
    fd.append("Email", iData.Email);
    fd.append("NoLisensi", iData.NoLisensi);
    fd.append("NoKtp", iData.NoKtp);
    fd.append("IdDesa", iData.SelectDesa.value);
    fd.append("IdJenisSurveyor", iData.IdJenisSurveyor.value);
    fd.append("IdWilker", iData.IdWilker.value);
    fd.append("IdKjsb", iData.IdKjsb?.value);

    var xi = 0;
    if (files !== "") {
      files.map((v, i) => {
        if (v.IdFile !== undefined) {
          fd.append(`Files[${xi}].IdFile`, v.IdFile);
          fd.append(`Files[${xi}].FileUpload`, v.FileUpload);
          xi++;
        }
      });
    }

    http
      .post(`${URLSVC}/Webs/EditSurveyor`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil memperbarui data`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_SURVEYOR", payload: false });
            dispatch(setDataSurveyors());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiEditSurveyorByUser = (iData, files, serkomFile) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("FirstName", iData.FirstName);
    fd.append("MiddleName", iData.MiddleName || "");
    fd.append("LastName", iData.LastName || "");
    fd.append("Address", iData.Address);
    fd.append("PhoneNumber", iData.PhoneNumber);
    fd.append("MobileNumber", iData.MobileNumber);
    fd.append("Email", iData.Email);
    fd.append("NoLisensi", iData.NoLisensi);
    fd.append("NoKtp", iData.NoKtp);
    fd.append("IdDesa", iData.SelectDesa.value);
    fd.append("IdWilker", iData.IdWilker.value);
    fd.append("SerkomFile", serkomFile);

    var xi = 0;
    if (files !== "") {
      files.map((v, i) => {
        if (v.IdFile !== undefined) {
          console.log("files : ", v.IdFile);
          fd.append(`Files[${xi}].IdFile`, v.IdFile);
          fd.append(`Files[${xi}].FileUpload`, v.FileUpload);
          xi++;
        }
      });
    }

    http
      .post(`${URLSVC}/Webs/EditSurveyorByUser`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil memperbarui data profil`,
            "success"
          ).then(function () {
            window.location.reload();
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiApproveDataSkb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdRegSkb", iData.IdRegSkb);
    fd.append("Catatan", iData.CatatanData);

    http
      .post(`${URLSVC}/Webs/ApproveDataSkb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil approve data`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_REG_SURVEYOR_DATA", payload: false });
            dispatch(setDataRegSkbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
export const apiRejectDataSkb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdRegSkb", iData.IdRegSkb);
    fd.append("Catatan", iData.CatatanData);

    http
      .post(`${URLSVC}/Webs/RejectDataSkb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil reject data`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_REG_SURVEYOR_DATA", payload: false });
            dispatch(setDataRegSkbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
export const apiApprovePaySkb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdRegSkb", iData.IdRegSkb);
    fd.append("Catatan", iData.CatatanPay);
    fd.append("JumlahTahun", iData.JumlahTahun.value);

    http
      .post(`${URLSVC}/Webs/ApprovePaySkb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil approve pembayaran`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_REG_SURVEYOR_PAY", payload: false });
            dispatch(setDataRegSkbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
export const apiRejectPaySkb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdRegSkb", iData.IdRegSkb);
    fd.append("Catatan", iData.CatatanPay);

    http
      .post(`${URLSVC}/Webs/RejectPaySkb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil reject pembayaran`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_REG_SURVEYOR_PAY", payload: false });
            dispatch(setDataRegSkbs());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiMoveSureveyorKjsb = (iData) => {
  return (dispatch) => {
    loadingBar(true);

    http
      .get(
        `${URLSVC}/Webs/MoveSureveyorKjsb?IdKjsb=${iData.IdKjsb.value}&IdSurveyor=${iData.IdSurveyor}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Berhasil pindahkan SB ke KJSB`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_SURVEYOR_NO_KJSB", payload: false });
            dispatch(setDataSurveyorNoKjsb());
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiUpdateDataRegSkb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdRegSkb", iData.IdRegSkb);
    fd.append("Email", iData.Email);
    fd.append("PasFoto", iData.PasFoto);

    http
      .post(`${URLSVC}/Webs/UpdateDataRegSkb`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire("Berhasil", `Berhasil perbarui data`, "success").then(
            function () {
              dispatch({ type: "MODAL_REG_SURVEYOR_DATA", payload: false });
              dispatch(setDataRegSkbs());
            }
          );
          var idFile = document.getElementById(`PasFoto`);
          idFile.value = idFile.defaultValue;
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiResendEmailPaySkb = (idRegSkb) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan kirim ulang email pembayaran!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(`${URLSVC}/Webs/ResendEmailPaySkb?IdRegSkb=${idRegSkb}`)
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil kirim ulang email pembayaran`,
                "success"
              ).then(function () {
                dispatch(setDataRegSkbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiResendEmailAkunSkb = (idRegSkb) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan kirim ulang email pembayaran diterima dan informasi akun!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(`${URLSVC}/Webs/ResendEmailAkunSkb?IdRegSkb=${idRegSkb}`)
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil kirim ulang email pembayaran diterima dan informasi akun`,
                "success"
              ).then(function () {
                dispatch(setDataRegSkbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
export const apiResendEmailPaySkbMassal = () => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan kirim ulang email pembayaran secara massal!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http.get(`${URLSVC}/Webs/ResendEmailPaySkbMassal`).then((res) => {
          loadingBar(false);
          let data = res.data;
          if (data.IsSuccess) {
            Swal.fire(
              "Berhasil",
              `Anda berhasil kirim ulang email pembayaran secara massal, hati-hati jangan sering melakukannya!`,
              "success"
            ).then(function () {
              dispatch(setDataRegSkbs());
            });
          } else {
            Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
          }
        });
      }
    });
  };
};
